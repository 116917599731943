module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ammattitaitoinen peltiseppä,  intohimona pelti ja peltityöt Heinolassa | Peltisepänliike Hannu Salminen","short_name":"Ammattitaitoinen peltiseppä,  intohimona pelti ja peltityöt Heinolassa | Peltisepänliike Hannu Salminen","start_url":"https://hannusalminen.fi","scope":"https://hannusalminen.fi","icon":"src/images/favicon.png","legacy":true,"display":"browser","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"693c0be9dbd4ecd7b1fd84cfe5a7c8b1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
